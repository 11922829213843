import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PageBanner from "../components/common/PageBanner"
import Layout from "../components/navigation/Layout"

const Leadership = () => {
  const data = useStaticQuery(graphql`
    query {
      allTeamJson {
        edges {
          node {
            id
            slug
            name
            title
            bio
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageBanner
        title=""
        backgroundImg="/img/backgrounds/headers/office3.jpg"
      />

      <section>
        <div className="container">
          {data.allTeamJson.edges.map(({ node: member }) => {
            return (
              <div
                key={member.id}
                className="row agent-list margin-40px-bottom padding-25px-all theme-shadow no-gutters align-items-center rounded-2xl"
              >
                <div className="col-md-4 col-lg-3 xs-margin-20px-bottom">
                  <div className="agent-image overflow-hidden rounded-2xl shadow-lg">
                    <img src={`/img/team/${member.slug}.jpg`} alt="" />
                  </div>
                </div>
                <div className="col-md-8 col-lg-9">
                  <div className="padding-40px-left xs-no-padding-lr padding-20px-right">
                    <h5 className="font-size20" style={{ marginBottom: "0px" }}>
                      <span className="text-extra-dark-gray">
                        {member.name}
                      </span>
                    </h5>
                    <span style={{ marginBottom: "20px" }}>{member.title}</span>
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={{ __html: member.bio }}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Leadership
