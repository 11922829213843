import React from "react"
import Footer from "./Footer"
import Header from "./Header"
import Helmet from "react-helmet" // TODO More apple attributes

const Layout = props => {
  return (
    <div className="main-wrapper">
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
