import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
        edges {
          node {
            frontmatter {
              slug
              title
              state
              type
              units
            }
          }
        }
      }
    }
  `)

  return (
    <header
      className={`position-absolute width-100 transparent-header scrollHeader`}
    >
      <div className="navbar-default">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12">
              <div className="menu_area">
                <nav className="navbar navbar-expand-lg navbar-light no-padding">
                  <div className="navbar-header navbar-header-custom p-0 h-24 flex justify-items-center items-center">
                    <div className="hidden sm:block">
                      <Link to="/" className="navbar-brand inner-logo">
                        <img id="logo" src="/img/logos/logo.jpg" alt="logo" />
                      </Link>
                    </div>
                  </div>

                  <div className="navbar-toggler"></div>
                  <ul
                    className="navbar-nav ml-auto "
                    id="nav"
                    style={{ display: "none" }}
                  >
                    <li className="text-blue-900">
                      <Link to="/">HOME</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">ABOUT US</Link>
                    </li>
                    <li>
                      <Link to="/leadership">LEADERSHIP</Link>
                    </li>
                    <li>
                      <Link to="/communities">COMMUNITIES</Link>
                      <ul className="md:overflow-y-scroll md:h-80">
                        {data.allMarkdownRemark.edges.map(edge => {
                          return (
                            <li key={edge.node.frontmatter.slug}>
                              <Link
                                to={`/properties/${edge.node.frontmatter.slug}`}
                              >
                                {edge.node.frontmatter.title}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                    <li>
                      <Link to="/news">NEWS</Link>
                    </li>
                    <li style={{ whiteSpace: "nowrap" }}>
                      <Link to="/contactus">CONTACT US</Link>
                    </li>
                    <li className="mt-[17px]" style={{ whiteSpace: "nowrap" }}>
                      <Link
                        to="/comingsoon"
                        className="px-3 py-1 bg-branding md:rounded-md"
                      >
                        INVESTOR LOGIN
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
