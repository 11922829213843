import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const navigation = {
  main: [
    { name: "About Us", href: "/aboutus" },
    { name: "Leadership", href: "/leadership" },
    { name: "Communities", href: "/communities" },
    { name: "News", href: "/news" },
    { name: "Contact Us", href: "/contactus" },
  ],
}
const Footer = () => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          author
          phoneNumber
          contactEmail
          address
        }
      }
    }
  `)
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map(item => (
            <div key={item.name} className="px-5 py-2">
              <Link
                to={item.href}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} {site.siteMetadata.title}
        </p>
      </div>
    </footer>
  )
}

export default Footer
